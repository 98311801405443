<template>
    <div>
      <b-button @click="toggleDrawer" class="m-3">Toggle Drawer</b-button>
      <div :class="['drawer', { 'drawer--open': isDrawerOpen }]">
        <div class="drawer__header">
          <b-button @click="toggleDrawer" class="drawer__close-btn">Close</b-button>
        </div>
        <div class="drawer__content">
          <h4 class="mb-3">Public Inspection: Supporting the Head Start Workforce and Consistent Quality Programming</h4>
          <p class="drawer__summary">
            The Environmental Protection Agency (EPA) is proposing significant new use rules (SNURs) for certain chemical substances under the Toxic Substances Control Act (TSCA). These rules require manufacturers and processors to notify the EPA at least 90 days before starting any activity deemed a significant new use, allowing the agency to evaluate potential risks.
          </p>
          
          <div class="drawer__metadata">
            <h5>Article Metadata</h5>
          </div>
          
          <div class="drawer__timeline mt-3">
            <h5>Alert Timeline</h5>
            <div class="timeline">
              <div class="timeline-item">
                <span>Published: August 12, 2024</span>
              </div>
              <div class="timeline-item">
                <span>Updated: August 13, 2024</span>
              </div>
              <div class="timeline-item">
                <span>Last Updated: August 26, 2024</span>
              </div>
            </div>
          </div>
          
          <div class="drawer__related-articles mt-3">
            <h5>Connected and similar articles</h5>
            <b-row>
              <b-col cols="6" v-for="n in 4" :key="n">
                <b-card :title="'Title ' + n" class="mb-3">
                  <p>Summary of article goes here...</p>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>
        
        <div class="drawer__footer d-flex justify-content-between p-3">
          <b-button variant="secondary">Back</b-button>
          <b-button variant="primary">Go To Source</b-button>
          <b-button variant="success">Next</b-button>
        </div>
      </div>
      <b-overlay v-if="isDrawerOpen" :show="isDrawerOpen" class="overlay" @click="toggleDrawer"></b-overlay>
    </div>
  </template>
  
  <script>
  import { BButton, BOverlay, BCard, BRow, BCol } from 'bootstrap-vue';
  
  export default {
    components: {
      BButton,
      BOverlay,
      BCard,
      BRow,
      BCol
    },
    data() {
      return {
        isDrawerOpen: false
      };
    },
    methods: {
      toggleDrawer() {
        this.isDrawerOpen = !this.isDrawerOpen;
      }
    }
  };
  </script>
  
  <style scoped>
  .drawer {
    position: fixed;
    top: 0;
    right: -70%;
    height: 100%;
    width: 70%;
    background-color: #fff;
    color: #333;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;
  }
  
  .drawer--open {
    right: 0;
  }
  
  .drawer__header {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    background-color: #4a148c;
  }
  
  .drawer__close-btn {
    background-color: #fff;
    border: none;
    color: #4a148c;
    font-size: 20px;
    cursor: pointer;
    z-index: 1001;
  }
  
  .drawer__content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  .drawer__summary {
    margin-bottom: 20px;
  }
  
  .drawer__metadata {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 5px;
  }
  
  .drawer__timeline {
    background-color: #e3f2fd;
    padding: 15px;
    border-radius: 5px;
  }
  
  .drawer__related-articles {
    margin-top: 20px;
  }
  
  .drawer__footer {
    background-color: #f5f5f5;
    border-top: 1px solid #ccc;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 500;
  }
  
  @media (max-width: 1200px) {
    .drawer {
      width: 60%;
    }
  }
  
  @media (max-width: 992px) {
    .drawer {
      width: 70%;
    }
  }
  
  @media (max-width: 768px) {
    .drawer {
      width: 80%;
    }
  }
  
  @media (max-width: 576px) {
    .drawer {
      width: 90%;
    }
  }
  </style>
  